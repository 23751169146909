<template>
  <div>
    <VDialog
      v-model="suitsVisible"
      :max-width="!$vuetify.breakpoint.smAndDown ? '800px' : '700px'"
      content-class="cross__dialog dialog-order"
      scrollable
      :transition="$dialogTransition"
    >
      <VCard class="crmForms__listDialog" :width="$vuetify.breakpoint.smAndDown ? '330' : ''">
        <div class="cross__inner">
          <v-btn fab small  elevation="0" @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <VCardTitle>
          <v-row class="mr-0" align="center">
            <v-col cols="12" md="6" class="crmForms__listDialog__header-title">
              <v-btn
                @click="onBack"
                small
                plain
                class="br-10 pa-0 ma-0"
                v-if="typeDialog != 0"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <span>
                {{ $t("crm.suit.title") }}
              </span>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="crmForms__listDialog__header-nav text-right"
              v-if="typeDialog === 0 && isAllowEditSuits"
            >
              <v-btn
                class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
                :class="{ 'w-100': $vuetify.breakpoint.mobile }"
                @click="addNewSuit"
              >
                <v-icon :left="$vuetify.breakpoint.mobile">mdi-plus</v-icon>
                {{ $vuetify.breakpoint.mobile ? $t("btn.create") : "" }}
              </v-btn>
            </v-col>
          </v-row>
        </VCardTitle>
        <VCardText class="px-4">
          <VTabsItems v-model="typeDialog">
            <VTabItem>
              <VDivider v-if="!$vuetify.breakpoint.mobile" />
              <v-data-table
                :headers="suitsListHeader"
                :items="suitsList"
                item-key="email"
                hide-default-footer
                :class="!$vuetify.breakpoint.smAndDown ? 'my-table supply crmForms__listDialog-table' : 'supply crmForms__listDialog-table'"
                locale="uk"
                dense
                loader-height="1"
                :items-per-page="suitsList.length"
                :no-data-text="$t('table.noData')"
                height="60vh"
                fixed-header
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.btn="{ item }" v-if="isAllowEditSuits">
                  <v-btn
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="btn-edit sm mr-3"
                    color="primary"
                    dense
                    small
                    @click="editSuit(item)"
                  >
                    <v-icon dense v-if="isAllowEditSuits">
                      mdi-pencil
                    </v-icon>
                    <v-icon dense v-if="!isAllowEditSuits">
                      mdi-cube
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="sm"
                    color="error"
                    depressed
                    dense
                    small
                    plain
                    @click="selectedItem = item; confirmDeleteDialog = true"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.name="{ item }">
                  <div>
                    <v-btn
                      v-if="!$vuetify.breakpoint.smAndDown"
                      class="btn-edit sm mr-3"
                      color="primary"
                      dense
                      small
                      @click="editSuit(item)"
                    >
                      <v-icon dense v-if="isAllowEditSuits">
                        mdi-pencil
                      </v-icon>
                       <v-icon dense v-if="!isAllowEditSuits">
                        mdi-cube
                      </v-icon>
                    </v-btn>
                    {{ item.name }}
                  </div>
                </template>

                <!-- eslint-disable-next-line  -->
                <template v-slot:item.sku="{ item }">
                  <span>
                    {{ item.sku ? item.sku : '--'}}
                  </span>
                </template>
                 <!-- eslint-disable-next-line  -->
                 <template v-slot:item.sku_sub="{ item }">
                 <span>
                   {{ item.sku_sub ? item.sku_sub : '--'}}
                 </span>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.uuid="{ item }">
                  <span class=" copyTxt" @click="CopyToClipboard(item.uuid)">
                    {{ item.uuid }}
                  </span>
                </template>

                <!-- eslint-disable-next-line  -->
                <template v-slot:item.price="{ item }">
                  <span>
                    {{ item.price || "0.00" }}
                  </span>
                </template>
              </v-data-table>
            </VTabItem>
            <VTabItem>
              <v-row class="my-0 py-0">
                <v-col cols="12">
                  <VTextField
                    v-model="newSuit.name"
                    outlined
                    dense
                    color="green"
                    class="br-10"
                    :placeholder="$t('form.title')"
                    hide-details="auto"
                    required
                    :error-messages="nameEditErrors"
                    @keypress="validationForTitle($event)"
                    @paste="validationForTitle($event)"
                  />
                </v-col>
                <v-col cols="12">
                  <VTextField
                    v-model="newSuit.sku"
                    outlined
                    dense
                    required
                    color="green"
                    class="br-10"
                    :placeholder="$t('crm.sku')"
                    hide-details="auto"
                    :error-messages="skuEditErrors"
                    @keypress="validationForNumbers($event)"
                    @paste="validationForNumbers($event)"
                  />
                </v-col>
                <v-col cols="12">
                  <VTextField
                    v-model="newSuit.sku_sub"
                    outlined
                    dense
                    color="green"
                    class="br-10"
                    :placeholder="$t('crm.sku_sub')"
                    hide-details
                    @keypress="validationForNumbers($event)"
                    @paste="validationForNumbers($event)"
                  />
                </v-col>
                <v-col cols="12">
                  <VTextarea
                    height="104"
                    no-resize
                    rows="3"
                    color="green"
                    class="br-10"
                    :placeholder="$t('form.addComment')"
                    v-model="newSuit.description"
                    hide-details
                    outlined
                    dense
                  ></VTextarea>
                </v-col>
              </v-row>
            </VTabItem>
          </VTabsItems>
        </VCardText>
        <VCardActions>
          <VContainer>
            <v-row>
              <!-- <v-col cols="12" v-if="typeDialog === 0">
                <v-btn class="w-100 br-10 text-transform-none" dark @click="onClose">
                  {{ $t("btn.close") }}
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" v-if="typeDialog === 1">
                <v-btn class="w-100 br-10 text-transform-none" dark @click="onCancelCreate">
                  {{ $t("btn.cancel") }}
                </v-btn>
              </v-col> -->
              <v-col cols="12" md="12" v-if="typeDialog === 1">
                <v-btn
                  class="success-bg text-white text-transform-none br-10 w-100"
                  dark
                  @click="onSave"
                >
                  {{ $t("btn.create") }}
                </v-btn>
              </v-col>
            </v-row>
          </VContainer>
        </VCardActions>
      </VCard>
    </VDialog>
    <SuitUpdateDialog
      v-if="editSuitConfig.visible"
      :visible="editSuitConfig.visible"
      :uuid_suit="editSuitConfig.uuid_suit"
      @close="onCloseEditSuit"
      :isAllowEditSuits="isAllowEditSuits"
      @suitListModified="onHandleSuitListModified"
    />
    <ConfirmDialog
      :visible="confirmDeleteDialog"
      @close="confirmDeleteDialog = false"
      @onConfirm="confirmDeleteDialog = false; onDeleteSuit(selectedItem)"
      @onCancel="confirmDeleteDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.deleteSuit.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.deleteSuit.desc") }}
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EventBus from "@/events/EventBus";
import crmSuits from "@/services/request/crm/crmSuits";
import SuitUpdateDialog from "./editSuit/SuitUpdateDialog.vue";
import ConfirmDialog from '@/components/dialog/ConfirmDialog.vue';
import {validationForNumbers, validationForTitle} from "@/mixins/helpers";

export default {
  props: {
    uuid_board: {
      require: true
    },
    boardUuid: {
      require: true
    },
    states: {
      require: true
    },
    isAllowEditSuits: {
      require: true
    },
    visible: {
      require: true,
    },
  },
  data: () => ({
    typeDialog: 0,
    suitsList: [],
    suitsListHeader: [],
    selectedUuidBoard: null,
    newSuit: {
      name: "",
      description: "",
      sku: "",
      sku_sub: ""
    },
    editSuitConfig: {
      visible: false,
      uuid_suit: null
    },
    confirmDeleteDialog: false,
    selectedItem: null,
  }),
  // watch: {
  //   suitsVisible() {
  //     if (this.suitsVisible) {
  //       this.renderSuitsTable();
  //       this.getSuitsList();
  //     }
  //   }
  // },
  mounted() {
    this.renderSuitsTable();
    this.getSuitsList();
  },
  methods: {
    async onHandleSuitListModified() {
      await this.renderSuitsTable();
      await this.getSuitsList();
    },
    validationForTitle,
    validationForNumbers,
    onBack(){
      this.typeDialog = 0;
      this.$v.$reset();
    },
    CopyToClipboard(data) {
      navigator.clipboard.writeText(data);
      this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
    },
    addNewSuit() {
      this.typeDialog = 1;
    },
    renderSuitsTable() {
      this.suitsListHeader = [
        {
          text: this.$t("form.title"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("crm.sku"),
          align: "left",
          sortable: false,
          value: "sku"
        },
        {
          text: this.$t("crm.sku_sub"),
          align: "left",
          sortable: false,
          value: "sku_sub"
        },
        {
          text: "uuid_product",
          align: "left",
          sortable: false,
          value: "uuid"
        },
        {
          text: `${this.$t("table.price")} (${this.$t("paymentPlan.uah")})`,
          align: "left",
          sortable: false,
          value: "price"
        },
      ];

      if (this.isAllowEditSuits) {
        this.suitsListHeader.push({
          text: this.$t("form.control"),
          align: "center",
          sortable: false,
          value: "btn",
          width: "10%"
        })
      }
    },
    async getSuitsList() {
      try {
        const res = await crmSuits.getList({ uuid_object: this.uuid_board });
        this.suitsList = res;

      } catch (e) {
        console.log(e)
      }
    },
    onClose() {
      this.onCancelCreate();
      this.suitsVisible = false;
    },
    onCloseEditSuit() {
      this.editSuitConfig.visible = false;
      this.editSuitConfig.uuid_suit = null;
    },
    onCancelCreate() {
      this.$v.$reset();
      this.typeDialog = 0;
      this.newSuit = {
        name: "",
        description: ""
      };
    },
    editSuit({ uuid }) {
      this.editSuitConfig.visible = true;
      this.editSuitConfig.uuid_suit = uuid;
    },
    async onSave() {
      try {
        const firstDigit = this.$v.newSuit.sku.$model.toString().charAt(0);
        this.$v.$touch();
        if (this.$v.$invalid || firstDigit === "0") return;

        const formData = new FormData();

        formData.append("uuid_object", this.uuid_board);
        formData.append("name", this.newSuit.name);
        formData.append("description", this.newSuit.description);
        formData.append("sku", this.newSuit.sku ? this.newSuit.sku : '');
        formData.append("sku_sub", this.newSuit.sku_sub ? this.newSuit.sku_sub : '');
        const res = await crmSuits.addSuit(formData);


        this.onCancelCreate();
        this.getSuitsList();

        if (res) {
          this.editSuitConfig = {
            visible: true,
            uuid_suit: res.uuid
          }
        }
        this.setSuccessNotification(this.$t("crm.notification.successCreated.suit"));
      } catch (e) {
        console.log(e)
      }
    },
    async onDeleteSuit({ uuid }) {
      try {
        await crmSuits.deleteSuit(uuid);
        this.getSuitsList();
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    nameEditErrors() {
      const errors = [];
      if (!this.$v.newSuit.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.newSuit.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    },
    skuEditErrors() {
      const errors = [];
      if (!this.$v.newSuit.sku.$dirty) {
        return errors;
      }
      const firstDigit = this.$v.newSuit.sku.$model.toString().charAt(0);

      if (firstDigit === "0") {
        errors.push(this.$t("form.errors.skuRequire"));
        return errors;
      }

      // eslint-disable-next-line no-unused-expressions
      !this.$v.newSuit.sku.required && errors.push(this.$t("form.errors.skuRequiered"));
      return errors;
    },
    suitsVisible: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  validations: {
    newSuit: {
      name: { required },
      sku: { required }
    }
  },
  components: {
    SuitUpdateDialog,
    ConfirmDialog,
  },
  mixins: [validationMixin, notifications]
};
</script>

<style></style>
