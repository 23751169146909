<template>
  <VDialog
    v-model="visibility"
    :max-width="chosenTab === 0 ? 500 : 1000"
    content-class="cross__dialog"
    scrollable
    :persistent="isChanged"
    @click:outside="onCancelHandler()"
    :transition="$dialogTransition"
  >
    <v-card :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-1'" min-height="400">
      <div class="cross__inner">
        <v-btn
          fab
          small
          elevation="0"
          @click="typeAction = 'info', onCancelHandler()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="pt-7 pb-1">
        <v-row no-gutters>
          <v-col cols="12" class="pb-0">
            <v-row justify="space-between">
              <v-col>
                <div class="d-flex justify-content-space-between">
                  <span class="d-flex align-center py-0">
                    <span v-if="isAllowEditSuits">{{ $t('crm.editing') }} </span><span class="mx-3" v-if="isAllowEditSuits">–</span> <b>{{ suitInfo.name }}</b>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="chosenTab" color="success">
          <v-tab>
            <v-icon left>mdi-cog-transfer-outline</v-icon>
            <div v-if="!$vuetify.breakpoint.smAndDown">
              {{ $t("crm.suit.edit.settings") }}
            </div>
          </v-tab>
          <v-tab>
            <v-icon left>mdi-package-variant-closed</v-icon>
            <div v-if="!$vuetify.breakpoint.smAndDown">
              {{ $t("crm.suit.edit.items") }}
            </div>
          </v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="chosenTab" class="pb-5">
          <v-tab-item>
            <edit-info ref="editInfoComponent" :suitInfo="suitInfo" :isAllowEditSuits="isAllowEditSuits" />
          </v-tab-item>
          <v-tab-item>
            <edit-items
              :isAllowEditSuits="isAllowEditSuits"
              :suitInfo="suitInfo"
              @changeTypeAction="changeTypeAction"
              :closeProductList="closeProductList"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="isAllowEditSuits">
        <v-container>
          <v-row>
            <!-- <v-col cols="12" md="6">
              <v-btn class="w-100 br-10 text-transform-none" dark @click="onCancelHandler">
                {{ $t("btn.cancel") }}
              </v-btn>
            </v-col> -->
            <v-col cols="12" v-if="chosenTab === 0">
              <v-btn
                class="success-bg text-white text-transform-none br-10 w-100"
                @click="onUpdateHandler"
                :disabled="!isChanged"
              >
                {{ $t("btn.update") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="$emit('close')"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import notifications from "@/mixins/notifications";
import EventBus from "@/events/EventBus";
import crmSuits from "@/services/request/crm/crmSuits";
import EditItems from "./tabs/editItems.vue";
import EditInfo from "./tabs/editInfo.vue";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";

export default {
  components: { EditItems, EditInfo, ConfirmLeftDialog },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    uuid_suit: {
      required: true
    },
    isAllowEditSuits: {
      require: true
    }
  },
  data: () => ({
    suitInfo: {},
    chosenTab: 0,
    typeAction: null,
    closeProductList: false,
    visibleConfirm: false,
    isChanged: false,
  }),
  watch: {
    chosenTab() {
      this.closeProductList = !this.closeProductList;
    }
  },
  async mounted() {
    EventBus.$on("on-change-info", ( isChanged) => {
      this.typeAction = "info";
      this.isChanged = isChanged;
    });
    EventBus.$on("on-update-info", (suitInfo) => {
      this.suitInfo = suitInfo;
      this.$emit("suitListModified");
    });
    await this.getSuitInfo();
  },
  methods: {
    changeTypeAction(typeTab, typeList) {
      this.typeProduct = typeList
      this.typeAction = typeTab;
    },
    async getSuitInfo() {
      try {
        const res = await crmSuits.getSuitInfo(this.uuid_suit);
        this.suitInfo = res;
      } catch (e) {
        console.log(e)
      }
    },
    onCancelHandler() {
      if (this.typeAction === "info") {
        if (this.isChanged) {
          this.visibleConfirm = true
        }
        else {
          this.suitInfoUpdated = {};
          this.$emit("close");
        }
      } else if (this.typeAction === "product") {
        this.closeProductList = !this.closeProductList;
      }
    },
    onUpdateHandler() {
      if (this.typeAction === "info") {
        this.$refs.editInfoComponent.updateInfo();
      }
    },
    checkChanged(isChanged) {
      this.isChanged = isChanged
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.resetTabs = true;
        this.$emit("close");
      }
    }
  },
  mixins: [validationMixin, notifications]
};
</script>

<style></style>
