<template>
  <div>
    <v-row class="my-0 py-0">
      <v-col cols="12">
        <VTextField
          v-model="copySuitInfo.name"
          outlined
          dense
          required
          color="success"
          class="br-10"
          :placeholder="$t('form.title')"
          hide-details="auto"
          :error-messages="nameEditErrors"
          :disabled="!isAllowEditSuits"
          maxlength="25"
          @keypress="validationForTitle($event)"
          @paste="validationForTitle($event)"
        />
      </v-col>
      <v-col cols="12">
        <VTextField
          v-model="copySuitInfo.sku"
          outlined
          dense
          color="success"
          class="br-10"
          required
          :placeholder="$t('crm.sku')"
          hide-details="auto"
          :error-messages="skuEditErrors"
          :disabled="!isAllowEditSuits"
          maxlength="40"
          @keypress="validationForNumbers($event)"
          @paste="validationForNumbers($event)"
        />
      </v-col>
      <v-col cols="12">
        <VTextField
          v-model="copySuitInfo.sku_sub"
          outlined
          dense
          color="success"
          class="br-10"
          :placeholder="$t('crm.sku_sub')"
          hide-details="auto"
          maxlength="40"
          :disabled="!isAllowEditSuits"
          @keypress="validationForNumbers($event)"
          @paste="validationForNumbers($event)"
        />
      </v-col>
      <v-col cols="12">
        <VTextarea
          height="104"
          no-resize
          rows="3"
          color="success"
          class="br-10"
          :placeholder="$t('form.addComment')"
          v-model="copySuitInfo.description"
          outlined
          hide-details
          dense
          :disabled="!isAllowEditSuits"
          maxlength="160"
        ></VTextarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import notifications from "@/mixins/notifications";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EventBus from "@/events/EventBus";
import {validationForNumbers, validationForTitle} from "@/mixins/helpers";
import crmSuits from "@/services/request/crm/crmSuits";

export default {
  methods: {
    validationForNumbers,
    validationForTitle,
    async updateInfo() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid || this.$v.copySuitInfo.sku.$model === "0") return;
        const formData = new FormData();
        formData.append("uuid", this.copySuitInfo.uuid);
        formData.append("name", this.copySuitInfo.name);
        formData.append("description", this.copySuitInfo.description);
        formData.append("sku", this.copySuitInfo.sku);
        formData.append("sku_sub", this.copySuitInfo.sku_sub);
        await crmSuits.updateSuits(formData);
        EventBus.$emit("on-update-info", this.copySuitInfo);
      } catch (e) {
        console.log(e)
      }
    },
  },
  props: {
    isAllowEditSuits: {
      require: true
    },
    suitInfo: {
      require: true,
    }
  },
  watch: {
    suitInfo: {
      deep: true,
      handler(e) {
        this.copySuitInfo = { ...this.suitInfo };
      }
    },
    copySuitInfo: {
      deep: true,
      handler(e) {
        this.isChanged = !_.isEqual(this.copySuitInfo, this.suitInfo);
        EventBus.$emit("on-change-info", this.isChanged);

      },
    },
  },
  data: () => ({
    copySuitInfo: {
      name: "",
      sku: ""
    },
    isChanged: false
  }),
  computed: {
    nameEditErrors() {
      const errors = [];
      if (!this.$v.copySuitInfo.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.copySuitInfo.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    },
    skuEditErrors() {
      const errors = [];
      if (!this.$v.copySuitInfo.sku.$dirty) {
        return errors;
      }
      if (this.$v.copySuitInfo.sku.$model === "0") {
        errors.push(this.$t("form.errors.skuRequire"))
        return errors;
      }

        // eslint-disable-next-line no-unused-expressions,no-unexpected-multiline
      !this.$v.copySuitInfo.sku.required && errors.push(this.$t("form.errors.skuRequiered"));
      return errors;
    }
  },
  validations: {
    copySuitInfo: {
      name: { required },
      sku: { required }
    }
  },
  mixins: [validationMixin, notifications]
};
</script>

<style></style>
