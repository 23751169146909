<template>
  <div>
    <v-tabs-items v-model="tabStep" :touchless="true">
      <v-tab-item>
        <div class="crm-form-items__wrapper">
          <v-row class="mt-1 pb-5">
            <v-col cols="12">
              <div class="title d-flex mb-1 justify-content-space-between align-center">
                <v-row></v-row>
                <!-- <VBtn
                  dense
                  plain
                  class="text-transform-none px-2"
                  style="color: green"
                  @click="onShowProductList('crm')"
                  v-if="isAllowEditSuits && $route.name != 'supply'"
                >
                  <VIcon :left="!$vuetify.breakpoint.smAndDown">mdi-plus</VIcon>
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{$t('supply.addCrm_itemBtn')}}
                  </div>
                  <div v-else>
                    CRM {{$t('supply.item')}}
                  </div>
                </VBtn> -->
                <VBtn
                  dense
                  plain
                  class="text-transform-none px-2"
                  style="color: green"
                  @click="onShowProductList('supply')"
                  v-if="isAllowEditSuits"
                >
                  <VIcon :left="!$vuetify.breakpoint.smAndDown">mdi-plus</VIcon>
                  <div v-if="!$vuetify.breakpoint.smAndDown">
                    {{ $t("supply.add_fromDeportBtn") }}
                  </div>
                  <div v-else>
                    {{ $t("supply.item") }}
                  </div>
                </VBtn>
              </div>
              <v-data-table
                :headers="listProductsHeader"
                :items="listProducts"
                item-key="email"
                hide-default-footer
                class="my-table supply crmForms__listDialog-table"
                locale="uk"
                dense
                loader-height="1"
                :items-per-page="listProducts.length"
                :no-data-text="$t('table.noData')"
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.product_name="{ item }">
                  {{ item.product_name }}
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.option_name="{ item }">
                  {{ item.option_name || "--" }}
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.price="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.price"
                    @save="onInlineChange(item, 'price')"
                    @cancel="onInlineCancel"
                    v-if="isAllowEditSuits"
                  >
                    <div class=" dashed-preview my-2">
                      {{ item.price || "0.00" }}
                    </div>
                    <template v-slot:input>
                      <v-text-field
                        v-model.number="item.price"
                        single-line
                        counter
                        :min="0"
                        dense
                        color="success"
                        @keypress="validationForPrice($event)"
                        :maxLength="6"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <div v-else class="dashed-preview my-2">
                    {{ item.price || "0.00" }}
                  </div>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.amount="{ item }">
                  <v-edit-dialog
                    v-if="isAllowEditSuits"
                    :return-value.sync="item.amount"
                    @save="confirmChangeToZero(item, 'amount')"
                    @cancel="onInlineCancel"
                  >
                    <div class=" dashed-preview my-2">
                      {{ item.amount }}
                    </div>
                    <template v-slot:input>
                      <v-text-field
                        v-model.number="item.amount"
                        single-line
                        counter
                        :min="0"
                        color="success"
                        dense
                        :maxLength="5"
                        @keypress="validationForNumbers($event)"
                        @paste="validationForNumbers($event)"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <div v-else class="dashed-preview my-2">
                    {{ item.amount }}
                  </div>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.btn="{ item }" v-if="isAllowEditSuits">
                  <VBtn
                    icon
                    class="sm"
                    color="error"
                    depressed
                    dense
                    small
                    plain
                    @click="selectedItem = item; confirmDialog = true"
                  >
                    <VIcon small>
                      mdi-close
                    </VIcon>
                  </VBtn>
                </template>
                <template v-slot:footer v-if="this.listProducts.length">
                  <hr />
                  <div
                    class="d-flex w-100"
                    style="justify-content: flex-end; height:auto;"
                  >
                    {{ getTotalPrice() }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <CrmFormListProduct v-if="showTabList" @back="backFormProduct()" :isCRMProducts="isCRMProducts" :isOption="true" ></CrmFormListProduct>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <ConfirmDialog
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @onConfirm="deleteProduct(selectedItem); confirmDialog = false;"
      @onCancel="
        confirmDialog = false;
      "
      :typeAlert="'warning'"
    >
      <template v-slot:header>
        {{$t('confirmDialog.accept_deleteTitle')}}
      </template>
      <template v-slot:description>
        {{$t('confirmDialog.accept_deleteGoodsDesc')}}
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      :visible="confirmZero"
      @close="onConfirmZero(confirmZeroItem); confirmZero = false; confirmZeroEmpty = true;"
      @onConfirm="onConfirmZero(confirmZeroItem); confirmZero = false;"
      @onCancel="confirmZero = false"
      :typeAlert="'warning'"
    >
      <template v-slot:header>
        {{$t('confirmDialog.accept_enteringZeroTitle')}}
      </template>
      <template v-slot:description>
        {{$t('confirmDialog.accept_enteringZero')}}
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import EventBus from "@/events/EventBus";
import notifications from "@/mixins/notifications";
import imgDialog from "@/components/products/imgDialog.vue";
import crmSuits from "@/services/request/crm/crmSuits";
import CrmFormListProduct from "@/components/crm/crmForms/crmEditFormDialog/tabs/productTabs/crmFormListProduct.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import {
  validationForNumbers,
  validationForPrice
} from "@/mixins/helpers";

export default {
  props: ["suitInfo", "closeProductList", "isAllowEditSuits"],
  data: () => ({
    selectedItem: null,
    confirmDialog: false,
    confirmZero: false,
    confirmZeroItem: 0,
    confirmZeroEmpty:false,
    listProducts: [],
    tabStep: 0,
    showTabList:false,
    isCRMProducts:false,
    visibleConfirm: false,
    deleteUUID: null,
    infoPrices: "",
    listProductsHeader: [
      {
        text: "Назва",
        align: "left",
        sortable: false,
        value: "product_name"
      },
      {
        text: "Опція",
        align: "left",
        sortable: false,
        value: "option_name"
      },
      {
        text: "Кількість",
        align: "center",
        sortable: false,
        value: "amount"
      },
      {
        text: "Ціна (грн)",
        align: "center",
        sortable: false,
        value: "price"
      },
      {
        text: "Загальна ціна (грн)",
        align: "center",
        sortable: false,
        value: "total_price"
      },
      {
        text: "",
        align: "center",
        sortable: false,
        value: "btn"
      }
    ]
  }),
  mounted(item) {
    EventBus.$on("crm-form-setProduct", async item => {
      this.addProduct(item);
    });
    this.getProductList();
    this.renderHeaders();
  },
  watch: {
    tabStep(e){
      this. showTabList = e == 1
    },
    closeProductList(e) {
      this.tabStep = 0;
      this.showTabList = false;
      this.$emit("changeTypeAction", "info");
    }
  },
  methods: {
    validationForNumbers,
    validationForPrice,
    confirmChangeToZero(item, key) {
      if (item.amount === 0 && !this.confirmZero) {
        this.confirmZeroItem = item;
        this.confirmZero = true;
      } else {
        this.onInlineChange(item, key);
      }
    },
    onConfirmZero(item) {
      if(!this.confirmZero){
        this.confirmZeroItem = '';
      } else {
        this.onInlineChange(item, 'amount');
        this.confirmZeroEmpty = false;
      }
    },
    async onInlineChange(item, key) {
      try {
        const formData = new FormData();
        formData.append("uuid", item.uuid);
        formData.append(key, item[key] || "0");
        await crmSuits.updateProduct(formData);

        this.getProductList();
      } catch (e) {
        console.log(e)
      }
    },
    onInlineCancel(e) {},
    onShowProductList(type) {
      this.isCRMProducts = type === 'crm'
      this.tabStep = 1;
      this.$emit("changeTypeAction", "product",type);
      this.showTabList = true;
    },
    async getProductList() {
      try {
        if (this.suitInfo.uuid) {
          const response = await crmSuits.getProductList({ uuid_suit: this.suitInfo.uuid });
          this.listProducts = response?.object;
          this.infoPrices = response?.message;


        }
      } catch (e) {
        console.log(e)
      }
    },
    async deleteProduct({ uuid }) {
      try {
        this.deleteUUID = uuid;
        await crmSuits.deleteProduct(uuid);
        this.deleteUUID = null;
        this.getProductList();
      } catch (e) {
        console.log(e)
      }
    },
    async addProduct(item) {
      try {
        if (!item) return;
        if (this.tabStep === 0) return;
        const formData = new FormData();
        formData.append("uuid_product", item.uuid_product || item.uuid);
        if (item.uuid_option) {
          formData.append("uuid_option", item.uuid_option);
        }
        formData.append("uuid_suit", this.suitInfo.uuid);

        await crmSuits.addProduct(formData);

        await this.getProductList();
        this.tabStep = 0;
      } catch (e) {
        this.setErrorNotification(e.error.description);
      }
    },
    backFormProduct() {

      this.getProductList();
      this.tabStep = 0;
    },
    getTotalPrice() {
      const str = this.infoPrices.split(",")[1];
      const text = str.split(":")[0].replace(/ /g, "");
      const number = str.split(":")[1];

      return `${this.$t(`table.${text}`)} - ${number} ${this.$t("paymentPlan.uah")}`;
    },
    renderHeaders() {
      this.listProductsHeader = [
        {
          text: this.$t("table.name"),
          align: "left",
          sortable: false,
          value: "product_name"
        },
        {
          text: this.$t("table.option"),
          align: "left",
          sortable: false,
          value: "option_name"
        },
        {
          text: this.$t("table.amount"),
          align: "center",
          sortable: false,
          value: "amount"
        },
        {
          text: `${this.$t("table.price")} (${this.$t('crm.uah')})`,
          align: "center",
          sortable: false,
          value: "price"
        },
        {
          text: `${this.$t("table.TotalPrice")} (${this.$t('crm.uah')})`,
          align: "center",
          sortable: false,
          value: "total_price"
        }
      ];
      if (this.isAllowEditSuits) {
        this.listProductsHeader.push({
          text: "",
          align: "center",
          sortable: false,
          value: "btn"
        });
      }
    }
  },
  computed: {},
  mixins: [notifications],
  components: {
    imgDialog,
    CrmFormListProduct,
    ConfirmDialog
  }
};
</script>

<style></style>
